import { withFormsy } from 'formsy-react';
import React from 'react';

class MyInput extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    validateOnChange = false

    changeValue(event) {
        // setValue() will set the value of the component, which in
        // turn will validate it and the rest of the form
        // Important: Don't skip this step. This pattern is required
        // for Formsy to work.
        this.props.setValue(event.currentTarget.value, this.validateOnChange);
        if (this.props.onChange)
            this.props.onChange(event)
    }

    validateValue = (event) => {
        const value = event.currentTarget.value
        if (!/\S/.test(value))
        {
            // not found something other than a space or line break
            event.currentTarget.value = event.currentTarget.value.trim()
        }
        
        this.validateOnChange = true;
        if (this.props.onChange)
            this.props.onChange(event)
        this.props.setValue(event.currentTarget.value);
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          this.validateValue(e)
        }
      }

    render() {
        // An error message is returned only if the component is invalid
        const errorMessage = !this.props.isPristine() && this.props.getErrorMessage();
        const showRequiredMessage = !this.props.isPristine() && !this.props.isValid() && this.props.showRequired();
        if (showRequiredMessage)
            this.validateOnChange = true
        const props = {
            disabled: this.props.disabled,
            placeholder: this.props.placeholder,
            onChange: this.changeValue,
            onBlur: this.validateValue,
            onKeyPress:this._handleKeyPress,
            type: this.props.type,
            value: this.props.getValue() || '',
            name: this.props.name,
            checked: this.props.checked,
            id: this.props.id,
            autoComplete: this.props.autoComplete,
            autoCorrect:this.props.autoCorrect,
            autoCapitalize:this.props.autoCapitalize,
            "data-tippy-content": this.props["data-tippy-content"]
        }
        return (
            <div style={{ ...this.props.style }} className={this.props.className}>
                {this.props.textarea && <textarea {...props} >{this.props.children}</textarea> || <input
                    {...props}
                />}
                <div className={"validation-error"} style={{ color: "#c10000", fontSize: 12, marginTop:2, ...this.props.validationErrorStyle }} >{showRequiredMessage && "Este campo es requerido" || errorMessage}</div>
            </div>
        );
    }
}

export default withFormsy(MyInput);